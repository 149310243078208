import { createRenderEffect, For, Show } from "solid-js";
import type { Magazine } from "~/types/drupal_jsonapi";
import {
  type ItemReferencePaginationStore,
  Pagination,
  paginationSlices,
} from "../shared/Pagination";
import { createStore } from "solid-js/store";
import { useSearchParams } from "@solidjs/router";
import { urlRs } from "~/utils/url";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import ArticleCard from "./ArticleCard";
import imgProxy from "~/utils/imgproxy";

import "~/components/shared/CommonPageStyle.css";
import "~/components/shared/CommonFirstScreen.css";
import "./MagazinePage.css";

const debug = false;

const [store, setStore] = createStore<ItemReferencePaginationStore>({
  slices: [],
  currentPage: 0,
  displayedItems: [],
});

export default function MagazinePage(props: Magazine) {
  const settings = useDrupalSettingsContext();
  const [searchParams] = useSearchParams();

  const path = () => {
    const dest = "/le-neuf-decrypte/l-actu-du-neuf/";
    return urlRs("articles", dest, settings);
  };

  createRenderEffect(() => {
    setStore("currentPage", parseInt(searchParams.page || "0"));
  });

  createRenderEffect(() => {
    const slices = paginationSlices(props.articles, {
      itemsPerPage: 10,
    });
    setStore("slices", slices);
  });

  createRenderEffect(() => {
    if (store.slices[store.currentPage]) {
      setStore(
        "displayedItems",
        store.slices[store.currentPage].filter(Boolean),
      );
    } else {
      setStore("displayedItems", []);
    }
  });

  return (
    <>
      <Show when={debug}>
        <ul>
          <li>Path: {path()}</li>
          <li>Page: {store.currentPage}</li>
          <li>Slices: {store.slices.length}</li>
          <li>DisplayedItemsLength: {store.displayedItems?.length}</li>
          <li>
            Articles nids: {store.displayedItems?.map((p) => p.id).join(", ")}
          </li>
          <li>
            All articles nids: {props.articles?.map((p) => p.id).join(", ")}
          </li>
        </ul>
      </Show>

      <article class="common-page-style magazine" data-ga-zone="in-page">
        <picture
          class="full-visual"
          data-ga-zone="first-screen"
          data-test="first-screen"
        >
          <source
            media="(min-width: 1367px)"
            srcset={imgProxy(
              "/images/magazine-bg.jpg",
              "size:1920:1080/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 1025px) and (max-width: 1366px)"
            srcset={imgProxy(
              "/images/magazine-bg.jpg",
              "size:1366:768/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 768px) and (max-width: 1024px)"
            srcset={imgProxy(
              "/images/magazine-bg.jpg",
              "size:1024:576/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 376px)"
            srcset={imgProxy(
              "/images/magazine-bg.jpg",
              "size:768:432/resizing_type:fill",
            )}
          />
          <source
            media="(max-width: 375px)"
            srcset={imgProxy(
              "/images/magazine-bg.jpg",
              "size:533:300/resizing_type:fill",
            )}
          />
          <img
            fetchpriority="high"
            src={imgProxy(
              "/images/magazine-bg.jpg",
              `size:533:300/resizing_type:fill`,
            )}
            alt="L’actu du neuf"
            height="300"
            width="375"
            loading="lazy"
          />
        </picture>

        <div class="outer-content-area">
          <div class="content-area">
            <span class="ribbon" />
            <div class="inner">
              <h1>L’actu du neuf</h1>
              <h2 class="page-catchline" data-test="catchline">
                L’actu du neuf Cogedim met en lumière les aspirations des
                Français et les nouvelles tendances en matière d’habitat. Un
                tour d’horizon immersif, ludique et interactif pour ceux qui
                rêvent ou nourrissent le projet de devenir propriétaire.
                Retrouvez des décryptages, des interviews et des sélections qui
                répondent à toutes vos questions. Comment aménager votre futur
                espace ? Quelles sont les nouvelles règlementations ? Quel
                financement pour votre projet ? Cogedim vous informe et vous
                accompagne afin de dénicher la perle rare : qui convient à vos
                goûts, à votre budget, et surtout à vos envies !
              </h2>

              <section data-test="articles">
                <Show when={props.top_article}>
                  <ArticleCard
                    nid={props.top_article!.id}
                    item={props.top_article!}
                    isPromoted={true}
                  />
                </Show>

                <div class="articles-list">
                  <For each={store.displayedItems}>
                    {(article) => (
                      <ArticleCard nid={article.id} item={article} />
                    )}
                  </For>
                </div>
              </section>

              <Show when={store.slices.length > 1}>
                <Pagination
                  currentPage={store.currentPage}
                  totalPages={store.slices.length}
                  url={path()!}
                  type="articles"
                />
              </Show>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
